const tableData1 = [
  {
    id: 1,
    value: [
      {
        "Serie": "100 050 02 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 153,
        "H": 331,
        "I": 80,
        "L": 411,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 060 02 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 153,
        "H": 381,
        "I": 80,
        "L": 461,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 070 02 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 153,
        "H": 429,
        "I": 80,
        "L": 509,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 036 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 200,
        "I": 69,
        "L": 269,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 050 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 246,
        "I": 69,
        "L": 315,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 060 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 279,
        "I": 69,
        "L": 348,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 070 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 312,
        "I": 69,
        "L": 381,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 080 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 346,
        "I": 69,
        "L": 415,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 090 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 381,
        "I": 69,
        "L": 450,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 105 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 429,
        "I": 69,
        "L": 498,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 050 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 246,
        "I": 82,
        "L": 328,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 060 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 279,
        "I": 82,
        "L": 361,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 070 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 312,
        "I": 82,
        "L": 394,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 080 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 346,
        "I": 82,
        "L": 428,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 090 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 379,
        "I": 82,
        "L": 461,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 105 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 429,
        "I": 82,
        "L": 511,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 115 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 462,
        "I": 82,
        "L": 544,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 048 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 198,
        "I": 71,
        "L": 269,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 060 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 228,
        "I": 71,
        "L": 299,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 068 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 248,
        "I": 71,
        "L": 319,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 085 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 290,
        "I": 71,
        "L": 361,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 105 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 340,
        "I": 71,
        "L": 411,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 115 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 365,
        "I": 71,
        "L": 436,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 060 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 230,
        "I": 84,
        "L": 314,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 068 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 250,
        "I": 84,
        "L": 334,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 085 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 292,
        "I": 84,
        "L": 376,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 095 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 317,
        "I": 84,
        "L": 401,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 105 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 342,
        "I": 84,
        "L": 426,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 120 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 379,
        "I": 84,
        "L": 463,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 130 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 405,
        "I": 84,
        "L": 489,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 150 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 455,
        "I": 84,
        "L": 539,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 170 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 505,
        "I": 84,
        "L": 589,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 200 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 579,
        "I": 84,
        "L": 663,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 050 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 178,
        "I": 74,
        "L": 252,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 070 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 218,
        "I": 74,
        "L": 292,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 085 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 247,
        "I": 74,
        "L": 321,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 105 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 288,
        "I": 74,
        "L": 362,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 115 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 308,
        "I": 74,
        "L": 382,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 125 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 328,
        "I": 74,
        "L": 402,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 150 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 378,
        "I": 74,
        "L": 452,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 185 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 448,
        "I": 74,
        "L": 522,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 060 06 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 145,
        "H": 178,
        "I": 76,
        "L": 254,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 085 06 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 145,
        "H": 218,
        "I": 76,
        "L": 294,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 105 06 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 145,
        "H": 247,
        "I": 76,
        "L": 323,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 115 06 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 145,
        "H": 269,
        "I": 76,
        "L": 345,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 125 06 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 145,
        "H": 288,
        "I": 76,
        "L": 364,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 100 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 291,
        "I": 87,
        "L": 378,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 125 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 341,
        "I": 87,
        "L": 428,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 150 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 391,
        "I": 87,
        "L": 478,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 170 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 431,
        "I": 87,
        "L": 518,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 185 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 461,
        "I": 87,
        "L": 548,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 215 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 521,
        "I": 87,
        "L": 608,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 250 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 592,
        "I": 87,
        "L": 679,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 150 06 10",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 200,
        "H": 350,
        "I": 89,
        "L": 439,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 170 06 10",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 200,
        "H": 383,
        "I": 89,
        "L": 472,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 190 06 10",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 200,
        "H": 415,
        "I": 89,
        "L": 505,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 230 06 10",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 200,
        "H": 483,
        "I": 89,
        "L": 572,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 260 06 10",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 200,
        "H": 530,
        "I": 89,
        "L": 619,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 100 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 297,
        "I": 87,
        "L": 384,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 125 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 347,
        "I": 87,
        "L": 461,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 150 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 397,
        "I": 87,
        "L": 484,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 185 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 467,
        "I": 87,
        "L": 554,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 215 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 527,
        "I": 87,
        "L": 614,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 260 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 617,
        "I": 87,
        "L": 704,
        "M": 115,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": "-"
      }
    ],
  },
  {
    id: 2,
    value: [
      {
        "Serie": "100 100 04 12",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 154,
        "H": 344,
        "I": 85,
        "L": 429,
        "M": 115,
        "N": 55,
        "P": 0,
        "Q": 0,
        "R": "1/2\"",
        "S": 0
      },
      {
        "Serie": "100 120 04 12",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 154,
        "H": 394,
        "I": 85,
        "L": 479,
        "M": 115,
        "N": 55,
        "P": 0,
        "Q": 0,
        "R": "1/2\"",
        "S": 0
      },
      {
        "Serie": "100 150 04 12",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 154,
        "H": 464,
        "I": 85,
        "L": 549,
        "M": 115,
        "N": 55,
        "P": 0,
        "Q": 0,
        "R": "1/2\"",
        "S": 0
      },
      {
        "Serie": "100 172 04 12",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 154,
        "H": 524,
        "I": 85,
        "L": 609,
        "M": 115,
        "N": 55,
        "P": 0,
        "Q": 0,
        "R": "1/2\"",
        "S": 0
      },
      {
        "Serie": "100 200 04 12",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 154,
        "H": 594,
        "I": 85,
        "L": 679,
        "M": 115,
        "N": 55,
        "P": 0,
        "Q": 0,
        "R": "1/2\"",
        "S": 0
      },
      {
        "Serie": "100 150 07 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 242,
        "H": 325,
        "I": 91,
        "L": 416,
        "M": 115,
        "N": 55,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 175 07 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 242,
        "H": 360,
        "I": 91,
        "L": 451,
        "M": 115,
        "N": 55,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 210 07 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 242,
        "H": 410,
        "I": 91,
        "L": 501,
        "M": 115,
        "N": 55,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 260 07 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 242,
        "H": 480,
        "I": 91,
        "L": 571,
        "M": 115,
        "N": 55,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 300 07 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 242,
        "H": 540,
        "I": 91,
        "L": 631,
        "M": 115,
        "N": 55,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 350 07 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 242,
        "H": 610,
        "I": 91,
        "L": 701,
        "M": 115,
        "N": 55,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 100 04 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 204,
        "H": 353,
        "I": 93,
        "L": 446,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 120 04 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 204,
        "H": 403,
        "I": 93,
        "L": 496,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 150 04 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 204,
        "H": 473,
        "I": 93,
        "L": 566,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 172 04 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 204,
        "H": 533,
        "I": 93,
        "L": 626,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 200 04 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 204,
        "H": 603,
        "I": 93,
        "L": 696,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 240 04 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 204,
        "H": 703,
        "I": 93,
        "L": 796,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 150 06 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 249,
        "H": 360,
        "I": 98,
        "L": 458,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 180 06 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 249,
        "H": 410,
        "I": 98,
        "L": 508,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 222 06 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 249,
        "H": 480,
        "I": 98,
        "L": 578,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 260 06 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 249,
        "H": 540,
        "I": 98,
        "L": 638,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 300 06 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 249,
        "H": 610,
        "I": 98,
        "L": 708,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 330 06 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 249,
        "H": 660,
        "I": 98,
        "L": 758,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 360 06 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 249,
        "H": 710,
        "I": 98,
        "L": 808,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 125 05 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 247,
        "H": 360,
        "I": 96,
        "L": 456,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 150 05 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 247,
        "H": 410,
        "I": 96,
        "L": 506,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 185 05 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 247,
        "H": 480,
        "I": 96,
        "L": 576,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 215 05 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 247,
        "H": 540,
        "I": 96,
        "L": 636,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 250 05 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 247,
        "H": 610,
        "I": 96,
        "L": 706,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 275 05 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 247,
        "H": 660,
        "I": 96,
        "L": 756,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 300 05 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 247,
        "H": 710,
        "I": 96,
        "L": 806,
        "M": 107,
        "N": 65,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 100 04 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 259,
        "H": 360,
        "I": 108,
        "L": 468,
        "M": 148,
        "N": 73,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 120 04 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 259,
        "H": 410,
        "I": 108,
        "L": 518,
        "M": 148,
        "N": 73,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 150 04 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 259,
        "H": 480,
        "I": 108,
        "L": 588,
        "M": 148,
        "N": 73,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 172 04 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 259,
        "H": 540,
        "I": 108,
        "L": 648,
        "M": 148,
        "N": 73,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 200 04 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 259,
        "H": 610,
        "I": 108,
        "L": 718,
        "M": 148,
        "N": 73,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 220 04 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 259,
        "H": 660,
        "I": 108,
        "L": 768,
        "M": 148,
        "N": 73,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      },
      {
        "Serie": "100 240 04 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 259,
        "H": 710,
        "I": 108,
        "L": 818,
        "M": 148,
        "N": 73,
        "P": 0,
        "Q": 0,
        "R": "3/4\"",
        "S": 0
      }
    ],
  },
  {
    id: 3,
    value: [
      {
        "Serie": "135 050 02 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 153,
        "H": 331,
        "I": 80,
        "L": 411,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 060 02 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 153,
        "H": 381,
        "I": 80,
        "L": 461,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 070 02 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 153,
        "H": 429,
        "I": 80,
        "L": 509,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 036 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 200,
        "I": 69,
        "L": 269,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 050 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 246,
        "I": 69,
        "L": 315,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 060 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 279,
        "I": 69,
        "L": 348,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 070 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 312,
        "I": 69,
        "L": 381,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 080 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 346,
        "I": 69,
        "L": 415,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 090 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 381,
        "I": 69,
        "L": 450,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 105 03 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 142,
        "H": 429,
        "I": 69,
        "L": 498,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 050 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 246,
        "I": 82,
        "L": 328,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 060 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 279,
        "I": 82,
        "L": 361,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 070 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 312,
        "I": 82,
        "L": 394,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 080 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 346,
        "I": 82,
        "L": 428,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 090 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 379,
        "I": 82,
        "L": 461,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 105 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 429,
        "I": 82,
        "L": 511,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 115 03 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 153,
        "H": 462,
        "I": 82,
        "L": 544,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 048 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 198,
        "I": 71,
        "L": 269,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 060 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 228,
        "I": 71,
        "L": 299,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 068 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 248,
        "I": 71,
        "L": 319,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 085 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 290,
        "I": 71,
        "L": 361,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 105 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 340,
        "I": 71,
        "L": 411,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 115 04 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 142,
        "H": 365,
        "I": 71,
        "L": 436,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 060 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 230,
        "I": 84,
        "L": 314,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 068 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 250,
        "I": 84,
        "L": 334,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 085 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 292,
        "I": 84,
        "L": 376,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 095 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 317,
        "I": 84,
        "L": 401,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 105 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 342,
        "I": 84,
        "L": 426,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 120 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 379,
        "I": 84,
        "L": 463,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 130 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 405,
        "I": 84,
        "L": 489,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 150 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 455,
        "I": 84,
        "L": 539,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 170 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 505,
        "I": 84,
        "L": 589,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 200 04 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 153,
        "H": 579,
        "I": 84,
        "L": 663,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 050 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 178,
        "I": 74,
        "L": 252,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 070 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 218,
        "I": 74,
        "L": 292,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 085 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 247,
        "I": 74,
        "L": 321,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 105 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 288,
        "I": 74,
        "L": 362,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 115 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 308,
        "I": 74,
        "L": 382,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 125 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 328,
        "I": 74,
        "L": 402,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 150 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 378,
        "I": 74,
        "L": 452,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 185 05 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 143,
        "H": 448,
        "I": 74,
        "L": 522,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 060 06 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 145,
        "H": 178,
        "I": 76,
        "L": 254,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 085 06 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 145,
        "H": 218,
        "I": 76,
        "L": 294,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 105 06 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 145,
        "H": 247,
        "I": 76,
        "L": 323,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 115 06 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 145,
        "H": 269,
        "I": 76,
        "L": 345,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 125 06 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 145,
        "H": 288,
        "I": 76,
        "L": 364,
        "M": 100,
        "N": 45,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 13
      },
      {
        "Serie": "135 100 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 291,
        "I": 87,
        "L": 378,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 125 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 341,
        "I": 87,
        "L": 428,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 150 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 391,
        "I": 87,
        "L": 478,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 170 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 431,
        "I": 87,
        "L": 518,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 185 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 461,
        "I": 87,
        "L": 548,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 215 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 521,
        "I": 87,
        "L": 608,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 250 05 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 156,
        "H": 592,
        "I": 87,
        "L": 679,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "1/2\"",
        "S": 15
      },
      {
        "Serie": "135 150 06 10",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 200,
        "H": 350,
        "I": 89,
        "L": 439,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": 15
      },
      {
        "Serie": "135 170 06 10",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 200,
        "H": 383,
        "I": 89,
        "L": 472,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": 15
      },
      {
        "Serie": "135 190 06 10",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 200,
        "H": 415,
        "I": 89,
        "L": 505,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": 15
      },
      {
        "Serie": "135 230 06 10",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 200,
        "H": 483,
        "I": 89,
        "L": 572,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": 15
      },
      {
        "Serie": "135 260 06 10",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 200,
        "H": 530,
        "I": 89,
        "L": 619,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": 15
      },
      {
        "Serie": "135 100 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 297,
        "I": 87,
        "L": 384,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": 15
      },
      {
        "Serie": "135 125 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 347,
        "I": 87,
        "L": 461,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": 15
      },
      {
        "Serie": "135 150 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 397,
        "I": 87,
        "L": 484,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": 15
      },
      {
        "Serie": "135 185 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 467,
        "I": 87,
        "L": 554,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": 15
      },
      {
        "Serie": "135 215 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 527,
        "I": 87,
        "L": 614,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": 15
      },
      {
        "Serie": "135 260 05 12",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 198,
        "H": 617,
        "I": 87,
        "L": 704,
        "M": 125,
        "N": 55,
        "P": "-",
        "Q": "-",
        "R": "3/4\"",
        "S": 15
      }
    ],
  },
  {
    id: 4,
    value: [
      {
        "Serie": "100 050 20 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 110,
        "H": 331,
        "I": 34,
        "L": 368,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 060 20 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 110,
        "H": 381,
        "I": 34,
        "L": 418,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 070 20 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 110,
        "H": 429,
        "I": 34,
        "L": 466,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 050 30 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 115,
        "H": 246,
        "I": 36,
        "L": 288,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 060 30 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 115,
        "H": 279,
        "I": 36,
        "L": 321,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 070 30 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 115,
        "H": 312,
        "I": 36,
        "L": 354,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 080 30 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 115,
        "H": 346,
        "I": 36,
        "L": 388,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 090 30 30",
        "A": 110,
        "B": 117,
        "C": 200,
        "D": 96,
        "E": 35,
        "G": 115,
        "H": 381,
        "I": 36,
        "L": 423,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 050 30 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 110,
        "H": 246,
        "I": 34,
        "L": 285,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 060 30 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 110,
        "H": 279,
        "I": 34,
        "L": 318,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 070 30 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 110,
        "H": 312,
        "I": 34,
        "L": 351,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 080 30 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 110,
        "H": 346,
        "I": 34,
        "L": 385,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 090 30 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 110,
        "H": 379,
        "I": 34,
        "L": 418,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 105 30 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 110,
        "H": 429,
        "I": 34,
        "L": 468,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 115 30 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 110,
        "H": 462,
        "I": 34,
        "L": 501,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 048 40 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 115,
        "H": 198,
        "I": 36,
        "L": 241,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 060 40 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 115,
        "H": 228,
        "I": 36,
        "L": 271,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 068 40 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 115,
        "H": 248,
        "I": 36,
        "L": 291,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 085 40 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 115,
        "H": 290,
        "I": 36,
        "L": 333,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 105 40 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 115,
        "H": 340,
        "I": 36,
        "L": 383,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 115 40 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 114,
        "E": 35,
        "G": 115,
        "H": 365,
        "I": 36,
        "L": 408,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 060 40 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 111,
        "H": 230,
        "I": 34,
        "L": 271,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 068 40 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 111,
        "H": 250,
        "I": 34,
        "L": 291,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 085 40 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 111,
        "H": 292,
        "I": 34,
        "L": 333,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 095 40 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 111,
        "H": 317,
        "I": 34,
        "L": 358,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 105 40 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 111,
        "H": 342,
        "I": 34,
        "L": 383,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 120 40 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 111,
        "H": 379,
        "I": 34,
        "L": 420,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 130 40 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 111,
        "H": 405,
        "I": 34,
        "L": 446,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 150 40 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 111,
        "H": 455,
        "I": 34,
        "L": 496,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 170 40 80",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 111,
        "H": 505,
        "I": 34,
        "L": 546,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 050 50 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 115,
        "H": 178,
        "I": 36,
        "L": 225,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 070 50 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 115,
        "H": 218,
        "I": 36,
        "L": 265,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 085 50 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 115,
        "H": 247,
        "I": 36,
        "L": 294,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 115 50 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 115,
        "H": 308,
        "I": 36,
        "L": 355,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 125 50 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 115,
        "H": 328,
        "I": 36,
        "L": 375,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 150 50 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 115,
        "H": 378,
        "I": 36,
        "L": 425,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 185 50 50",
        "A": 145,
        "B": 150,
        "C": 232,
        "D": 127,
        "E": 40,
        "G": 115,
        "H": 448,
        "I": 36,
        "L": 495,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 100 50 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 113,
        "H": 291,
        "I": 34,
        "L": 336,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 125 50 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 113,
        "H": 341,
        "I": 34,
        "L": 386,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 150 50 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 113,
        "H": 391,
        "I": 34,
        "L": 436,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 185 50 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 113,
        "H": 461,
        "I": 34,
        "L": 506,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 215 50 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 113,
        "H": 521,
        "I": 34,
        "L": 566,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 250 50 90",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 113,
        "H": 592,
        "I": 34,
        "L": 637,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "1/2\"",
        "S": "-"
      }
    ],
  },
  {
    id: 5,
    value: [
      {
        "Serie": "100 100 40 12",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 119,
        "H": 344,
        "I": 42,
        "L": 395,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 120 40 12",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 119,
        "H": 394,
        "I": 42,
        "L": 445,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 150 40 12",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 119,
        "H": 464,
        "I": 42,
        "L": 513,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 172 40 12",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 119,
        "H": 524,
        "I": 42,
        "L": 575,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 200 40 12",
        "A": 170,
        "B": 175,
        "C": 262,
        "D": 152,
        "E": 45,
        "G": 119,
        "H": 594,
        "I": 42,
        "L": 645,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "1/2\"",
        "S": "-"
      },
      {
        "Serie": "100 150 70 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 199,
        "H": 325,
        "I": 34,
        "L": 373,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 175 70 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 199,
        "H": 360,
        "I": 34,
        "L": 408,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 210 70 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 199,
        "H": 410,
        "I": 34,
        "L": 458,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 260 70 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 199,
        "H": 480,
        "I": 34,
        "L": 528,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 300 70 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 199,
        "H": 540,
        "I": 34,
        "L": 588,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 350 70 12",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 199,
        "H": 610,
        "I": 34,
        "L": 658,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 100 40 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 161,
        "H": 353,
        "I": 42,
        "L": 402,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 120 40 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 161,
        "H": 403,
        "I": 42,
        "L": 452,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 150 40 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 161,
        "H": 473,
        "I": 42,
        "L": 522,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 172 40 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 161,
        "H": 533,
        "I": 42,
        "L": 582,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 200 40 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 161,
        "H": 603,
        "I": 42,
        "L": 652,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 240 40 18",
        "A": 195,
        "B": 200,
        "C": 293,
        "D": 168,
        "E": 50,
        "G": 161,
        "H": 703,
        "I": 42,
        "L": 752,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 150 60 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 205,
        "H": 360,
        "I": 42,
        "L": 414,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 180 60 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 205,
        "H": 410,
        "I": 42,
        "L": 464,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 222 60 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 205,
        "H": 480,
        "I": 42,
        "L": 534,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 260 60 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 205,
        "H": 540,
        "I": 42,
        "L": 594,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 300 60 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 205,
        "H": 610,
        "I": 42,
        "L": 664,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 330 60 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 205,
        "H": 660,
        "I": 42,
        "L": 714,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 360 60 18",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 205,
        "H": 710,
        "I": 42,
        "L": 764,
        "M": 30,
        "N": "-",
        "P": 66,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 125 50 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 202,
        "H": 360,
        "I": 42,
        "L": 411,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 150 50 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 202,
        "H": 410,
        "I": 42,
        "L": 461,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 185 50 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 202,
        "H": 480,
        "I": 42,
        "L": 531,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 215 50 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 202,
        "H": 540,
        "I": 42,
        "L": 591,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 250 50 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 202,
        "H": 610,
        "I": 42,
        "L": 661,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 275 50 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 202,
        "H": 660,
        "I": 42,
        "L": 711,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 300 50 20",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 202,
        "H": 710,
        "I": 42,
        "L": 761,
        "M": 30,
        "N": "-",
        "P": 86,
        "Q": 36,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 100 40 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 208,
        "H": 360,
        "I": 50,
        "L": 417,
        "M": 35,
        "N": "-",
        "P": 105,
        "Q": 46,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 120 40 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 208,
        "H": 410,
        "I": 50,
        "L": 467,
        "M": 35,
        "N": "-",
        "P": 105,
        "Q": 46,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 150 40 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 208,
        "H": 480,
        "I": 50,
        "L": 537,
        "M": 35,
        "N": "-",
        "P": 105,
        "Q": 46,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 172 40 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 208,
        "H": 540,
        "I": 50,
        "L": 597,
        "M": 35,
        "N": "-",
        "P": 105,
        "Q": 46,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 200 40 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 208,
        "H": 610,
        "I": 50,
        "L": 667,
        "M": 35,
        "N": "-",
        "P": 105,
        "Q": 46,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "100 220 40 22",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 208,
        "H": 660,
        "I": 50,
        "L": 717,
        "M": 35,
        "N": "-",
        "P": 105,
        "Q": 46,
        "R": "3/4\"",
        "S": "-"
      },
      {
        "Serie": "",
        "A": 220,
        "B": 230,
        "C": 320,
        "D": 193,
        "E": 50,
        "G": 208,
        "H": 710,
        "I": 50,
        "L": 767,
        "M": 35,
        "N": "-",
        "P": 105,
        "Q": 46,
        "R": "3/4\"",
        "S": "-"
      }
    ],
  },
  {
    id: 6,
    value: [
      {
        "Serie": "101 050 21 30",
        "A": 110,
        "B": 115,
        "C": 200,
        "D": 95,
        "E": 45,
        "G": "-",
        "H": 328,
        "I": 34,
        "L": 365,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 060 21 30",
        "A": 110,
        "B": 115,
        "C": 200,
        "D": 95,
        "E": 45,
        "G": "-",
        "H": 378,
        "I": 34,
        "L": 415,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 070 21 30",
        "A": 110,
        "B": 115,
        "C": 200,
        "D": 95,
        "E": 45,
        "G": "-",
        "H": 426,
        "I": 34,
        "L": 463,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 050 31 30",
        "A": 110,
        "B": 115,
        "C": 200,
        "D": 95,
        "E": 45,
        "G": "-",
        "H": 243,
        "I": 36,
        "L": 285,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 060 31 30",
        "A": 110,
        "B": 115,
        "C": 200,
        "D": 95,
        "E": 45,
        "G": "-",
        "H": 276,
        "I": 36,
        "L": 318,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 070 31 30",
        "A": 110,
        "B": 115,
        "C": 200,
        "D": 95,
        "E": 45,
        "G": "-",
        "H": 309,
        "I": 36,
        "L": 351,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 080 31 30",
        "A": 110,
        "B": 115,
        "C": 200,
        "D": 95,
        "E": 45,
        "G": "-",
        "H": 343,
        "I": 36,
        "L": 385,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 090 31 30",
        "A": 110,
        "B": 115,
        "C": 200,
        "D": 95,
        "E": 45,
        "G": "-",
        "H": 378,
        "I": 36,
        "L": 420,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 105 31 30",
        "A": 110,
        "B": 115,
        "C": 200,
        "D": 95,
        "E": 45,
        "G": "-",
        "H": 426,
        "I": 36,
        "L": 468,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 050 31 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 240,
        "I": 34,
        "L": 280,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 060 31 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 273,
        "I": 34,
        "L": 313,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 070 31 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 306,
        "I": 34,
        "L": 346,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 080 31 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 340,
        "I": 34,
        "L": 380,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 090 31 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 373,
        "I": 34,
        "L": 413,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 105 31 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 423,
        "I": 34,
        "L": 463,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 115 31 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 456,
        "I": 34,
        "L": 496,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 048 41 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 192,
        "I": 36,
        "L": 236,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 060 41 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 222,
        "I": 36,
        "L": 266,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 068 41 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 242,
        "I": 36,
        "L": 286,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 085 41 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 284,
        "I": 36,
        "L": 328,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 105 41 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 334,
        "I": 36,
        "L": 378,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 115 41 50",
        "A": 130,
        "B": 135,
        "C": 220,
        "D": 112,
        "E": 45,
        "G": "-",
        "H": 359,
        "I": 36,
        "L": 403,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 060 41 80",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 230,
        "I": 34,
        "L": 272,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 068 41 80",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 250,
        "I": 34,
        "L": 292,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 085 41 80",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 292,
        "I": 34,
        "L": 334,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 095 41 80",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 317,
        "I": 34,
        "L": 359,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 105 41 80",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 342,
        "I": 34,
        "L": 384,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 120 41 80",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 379,
        "I": 34,
        "L": 421,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 130 41 80",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 405,
        "I": 34,
        "L": 447,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 150 41 80",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 455,
        "I": 34,
        "L": 497,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 170 41 80",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 505,
        "I": 34,
        "L": 547,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 050 51 50",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 180,
        "I": 36,
        "L": 225,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 070 51 50",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 220,
        "I": 36,
        "L": 265,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 085 51 50",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 248,
        "I": 36,
        "L": 294,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 115 51 50",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 309,
        "I": 36,
        "L": 355,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 125 51 50",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 329,
        "I": 36,
        "L": 375,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 150 51 50",
        "A": 145,
        "B": 150,
        "C": 230,
        "D": 126,
        "E": 45,
        "G": "-",
        "H": 379,
        "I": 36,
        "L": 425,
        "M": 24,
        "N": "-",
        "P": 45,
        "Q": 26,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 100 51 90",
        "A": 170,
        "B": 175,
        "C": 265,
        "D": 150,
        "E": 45,
        "G": "-",
        "H": 291,
        "I": 34,
        "L": 334,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 125 51 90",
        "A": 170,
        "B": 175,
        "C": 265,
        "D": 150,
        "E": 45,
        "G": "-",
        "H": 341,
        "I": 34,
        "L": 384,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 150 51 90",
        "A": 170,
        "B": 175,
        "C": 265,
        "D": 150,
        "E": 45,
        "G": "-",
        "H": 391,
        "I": 34,
        "L": 434,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 185 51 90",
        "A": 170,
        "B": 175,
        "C": 265,
        "D": 150,
        "E": 45,
        "G": "-",
        "H": 461,
        "I": 34,
        "L": 504,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      },
      {
        "Serie": "101 215 51 90",
        "A": 170,
        "B": 175,
        "C": 265,
        "D": 150,
        "E": 45,
        "G": "-",
        "H": 521,
        "I": 34,
        "L": 564,
        "M": 27,
        "N": "-",
        "P": 60,
        "Q": 31,
        "R": "\"",
        "S": "-"
      }
    ],
  },
];

export default tableData1;
