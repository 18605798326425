const tableData2 = [
  {
    id: 1,
    value: [{
      "Serie": "100 050 02 30",
      "Descriere": "50-02-30",
      "Lungime": 500,
      "Nr. Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 20,
      "Conținut Ulei": "2,4"
    },
    {
      "Serie": "100 060 02 30",
      "Descriere": "60-02-30",
      "Lungime": 600,
      "Nr. Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "2,8"
    },
    {
      "Serie": "100 070 02 30",
      "Descriere": "70-02-30",
      "Lungime": 700,
      "Nr. Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 24,
      "Conținut Ulei": "3,2"
    },
    {
      "Serie": "100 036 03 30",
      "Descriere": "36-03-30",
      "Lungime": 360,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 13,
      "Conținut Ulei": "1,6"
    },
    {
      "Serie": "100 050 03 30",
      "Descriere": "50-03-30",
      "Lungime": 500,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 14,
      "Conținut Ulei": "2"
    },
    {
      "Serie": "100 060 03 30",
      "Descriere": "60-03-30",
      "Lungime": 600,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 16,
      "Conținut Ulei": "2,4"
    },
    {
      "Serie": "100 070 03 30",
      "Descriere": "70-03-30",
      "Lungime": 700,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 17,
      "Conținut Ulei": "2,7"
    },
    {
      "Serie": "100 080 03 30",
      "Descriere": "80-03-30",
      "Lungime": 800,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 19,
      "Conținut Ulei": "3,1"
    },
    {
      "Serie": "100 090 03 30",
      "Descriere": "90-03-30",
      "Lungime": 900,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 20,
      "Conținut Ulei": "3,4"
    },
    {
      "Serie": "100 105 03 30",
      "Descriere": "0105-03-30",
      "Lungime": 1050,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 22,
      "Conținut Ulei": "4"
    },
    {
      "Serie": "100 050 03 50",
      "Descriere": "50-03-50",
      "Lungime": 500,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "3,0"
    },
    {
      "Serie": "100 060 03 50",
      "Descriere": "60-03-50",
      "Lungime": 600,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 23,
      "Conținut Ulei": "3,6"
    },
    {
      "Serie": "100 070 03 50",
      "Descriere": "70-03-50",
      "Lungime": 700,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 25,
      "Conținut Ulei": "4,0"
    },
    {
      "Serie": "100 080 03 50",
      "Descriere": "80-03-50",
      "Lungime": 800,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 27,
      "Conținut Ulei": "4,6"
    },
    {
      "Serie": "100 090 03 50",
      "Descriere": "90-03-50",
      "Lungime": 900,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 29,
      "Conținut Ulei": "5,1"
    },
    {
      "Serie": "100 105 03 50",
      "Descriere": "105-03-50",
      "Lungime": 1050,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 32,
      "Conținut Ulei": "5,9"
    },
    {
      "Serie": "100 115 03 50",
      "Descriere": "115-03-50",
      "Lungime": 1150,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 35,
      "Conținut Ulei": "6,4"
    },
    {
      "Serie": "100 048 04 50",
      "Descriere": "48-04-50",
      "Lungime": 480,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 17,
      "Conținut Ulei": "2,6"
    },
    {
      "Serie": "100 060 04 50",
      "Descriere": "60-04-50",
      "Lungime": 600,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 18,
      "Conținut Ulei": "3,1"
    },
    {
      "Serie": "100 068 04 50",
      "Descriere": "68-04-50",
      "Lungime": 680,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 19,
      "Conținut Ulei": "3,5"
    },
    {
      "Serie": "100 085 04 50",
      "Descriere": "85-04-50",
      "Lungime": 850,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 22,
      "Conținut Ulei": "4,2"
    },
    {
      "Serie": "100 105 04 50",
      "Descriere": "105-04-50",
      "Lungime": 1050,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 25,
      "Conținut Ulei": "5,1"
    },
    {
      "Serie": "100 115 04 50",
      "Descriere": "115-04-50",
      "Lungime": 1150,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 27,
      "Conținut Ulei": "5,5"
    },
    {
      "Serie": "100 060 04 80",
      "Descriere": "60-04-80",
      "Lungime": 600,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 24,
      "Conținut Ulei": "4,5"
    },
    {
      "Serie": "100 068 04 80",
      "Descriere": "68-04-80",
      "Lungime": 680,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 25,
      "Conținut Ulei": "5,0"
    },
    {
      "Serie": "100 085 04 80",
      "Descriere": "85-04-80",
      "Lungime": 850,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 28,
      "Conținut Ulei": "6,1"
    },
    {
      "Serie": "100 095 04 80",
      "Descriere": "95-04-80",
      "Lungime": 950,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 30,
      "Conținut Ulei": "6,8"
    },
    {
      "Serie": "100 105 04 80",
      "Descriere": "105-04-80",
      "Lungime": 1050,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 32,
      "Conținut Ulei": "7,4"
    },
    {
      "Serie": "100 120 04 80",
      "Descriere": "120-04-80",
      "Lungime": 1200,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 35,
      "Conținut Ulei": "8,4"
    },
    {
      "Serie": "100 130 04 80",
      "Descriere": "130-04-80",
      "Lungime": 1300,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 36,
      "Conținut Ulei": "9,0"
    },
    {
      "Serie": "100 150 04 80",
      "Descriere": "150-04-80",
      "Lungime": 1500,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 40,
      "Conținut Ulei": "10,3"
    },
    {
      "Serie": "100 170 04 80",
      "Descriere": "170-04-80",
      "Lungime": 1700,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 44,
      "Conținut Ulei": "11,6"
    },
    {
      "Serie": "100 200 04 80",
      "Descriere": "200-04-80",
      "Lungime": 2000,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 53,
      "Conținut Ulei": "13,5"
    },
    {
      "Serie": "100 050 05 50",
      "Descriere": "50-05-50",
      "Lungime": 500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 19,
      "Conținut Ulei": "3,5"
    },
    {
      "Serie": "100 070 05 50",
      "Descriere": "70-05-50",
      "Lungime": 700,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "4,6"
    },
    {
      "Serie": "100 085 05 50",
      "Descriere": "85-05-50",
      "Lungime": 850,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 23,
      "Conținut Ulei": "5,4"
    },
    {
      "Serie": "100 105 05 50",
      "Descriere": "105-05-50",
      "Lungime": 1050,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 26,
      "Conținut Ulei": "6,5"
    },
    {
      "Serie": "100 115 05 50",
      "Descriere": "115-05-50",
      "Lungime": 1150,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 28,
      "Conținut Ulei": "7,0"
    },
    {
      "Serie": "100 125 05 50",
      "Descriere": "125-05-50",
      "Lungime": 1250,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 29,
      "Conținut Ulei": "7,6"
    },
    {
      "Serie": "100 150 05 50",
      "Descriere": "150-05-50",
      "Lungime": 1500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 33,
      "Conținut Ulei": "9,0"
    },
    {
      "Serie": "100 185 05 50",
      "Descriere": "185-05-50",
      "Lungime": 1850,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 38,
      "Conținut Ulei": "11,0"
    },
    {
      "Serie": "100 060 06 50",
      "Descriere": "60-06-50",
      "Lungime": 600,
      "Nr. Unitati": 6,
      "D32": "O",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 18,
      "Conținut Ulei": "3,6"
    },
    {
      "Serie": "100 085 06 50",
      "Descriere": "85-06-50",
      "Lungime": 850,
      "Nr. Unitati": 6,
      "D32": "O",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "4,9"
    },
    {
      "Serie": "100 105 06 50",
      "Descriere": "105-06-50",
      "Lungime": 1020,
      "Nr. Unitati": 6,
      "D32": "O",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 23,
      "Conținut Ulei": "5,6"
    },
    {
      "Serie": "100 115 06 50",
      "Descriere": "115-06-50",
      "Lungime": 1150,
      "Nr. Unitati": 6,
      "D32": "O",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 25,
      "Conținut Ulei": "6,3"
    },
    {
      "Serie": "100 125 06 50",
      "Descriere": "125-06-50",
      "Lungime": 1250,
      "Nr. Unitati": 6,
      "D32": "O",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 26,
      "Conținut Ulei": "6,8"
    },
    {
      "Serie": "100 100 05 90",
      "Descriere": "100-05-90",
      "Lungime": 1000,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 39,
      "Conținut Ulei": "9,2"
    },
    {
      "Serie": "100 125 05 90",
      "Descriere": "125-05-90",
      "Lungime": 1250,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 45,
      "Conținut Ulei": "11,2"
    },
    {
      "Serie": "100 150 05 90",
      "Descriere": "150-05-90",
      "Lungime": 1500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 50,
      "Conținut Ulei": "13,1"
    },
    {
      "Serie": "100 170 05 90",
      "Descriere": "170-05-90",
      "Lungime": 1700,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 54,
      "Conținut Ulei": "14,7"
    },
    {
      "Serie": "100 185 05 90",
      "Descriere": "185-05-90",
      "Lungime": 1850,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 58,
      "Conținut Ulei": "15,9"
    },
    {
      "Serie": "100 215 05 90",
      "Descriere": "215-05-90",
      "Lungime": 2150,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 61,
      "Conținut Ulei": "18,3"
    },
    {
      "Serie": "100 250 05 90",
      "Descriere": "250-05-90",
      "Lungime": 2500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 70,
      "Conținut Ulei": "21,0"
    },
    {
      "Serie": "100 150 06 10",
      "Descriere": "150-06-100",
      "Lungime": 1500,
      "Nr. Unitati": 6,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 10000,
      "Greutate(KG)": 56,
      "Conținut Ulei": "16,4"
    },
    {
      "Serie": "100 170 06 10",
      "Descriere": "170-06-100",
      "Lungime": 1700,
      "Nr. Unitati": 6,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 10000,
      "Greutate(KG)": 62,
      "Conținut Ulei": "18,5"
    },
    {
      "Serie": "100 190 06 10",
      "Descriere": "190-06-100",
      "Lungime": 1900,
      "Nr. Unitati": 6,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 10000,
      "Greutate(KG)": 65,
      "Conținut Ulei": "20,2"
    },
    {
      "Serie": "100 230 06 10",
      "Descriere": "230-06-100",
      "Lungime": 2300,
      "Nr. Unitati": 6,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 10000,
      "Greutate(KG)": 74,
      "Conținut Ulei": "24,1"
    },
    {
      "Serie": "100 260 06 10",
      "Descriere": "260-06-100",
      "Lungime": 2580,
      "Nr. Unitati": 6,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 10000,
      "Greutate(KG)": 80,
      "Conținut Ulei": "26,9"
    },
    {
      "Serie": "100 100 05 12",
      "Descriere": "100-05-120",
      "Lungime": 1000,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 48,
      "Conținut Ulei": "12,6"
    },
    {
      "Serie": "100 125 05 12",
      "Descriere": "125-05-120",
      "Lungime": 1250,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 54,
      "Conținut Ulei": "15,4"
    },
    {
      "Serie": "100 150 05 12",
      "Descriere": "150-05-120",
      "Lungime": 1500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 61,
      "Conținut Ulei": "18,1"
    },
    {
      "Serie": "100 185 05 12",
      "Descriere": "185-05-120",
      "Lungime": 1850,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 70,
      "Conținut Ulei": "21,8"
    },
    {
      "Serie": "100 215 05 12",
      "Descriere": "215-05-120",
      "Lungime": 2150,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 79,
      "Conținut Ulei": "25,1"
    },
    {
      "Serie": "100 260 05 12",
      "Descriere": "260-05-120",
      "Lungime": 2600,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 90,
      "Conținut Ulei": "29,9"
    }]
    
  },
  {
    id: 2,
    value: [{
      "Serie": "100 100 04 12",
      "Descriere": "100-04-120",
      "Lungime": 1000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "D166": "",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 53,
      "Conținut Ulei": "9,6"
    },
    {
      "Serie": "100 120 04 12",
      "Descriere": "120-04-120",
      "Lungime": 1200,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "D166": "",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 56,
      "Conținut Ulei": "11,4"
    },
    {
      "Serie": "100 150 04 12",
      "Descriere": "150-04-120",
      "Lungime": 1480,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "D166": "",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 60,
      "Conținut Ulei": "13,9"
    },
    {
      "Serie": "100 172 04 12",
      "Descriere": "172-04-120",
      "Lungime": 1720,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "D166": "",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 64,
      "Conținut Ulei": "16,8"
    },
    {
      "Serie": "100 200 04 12",
      "Descriere": "200-04-120",
      "Lungime": 2000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "D166": "",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 68,
      "Conținut Ulei": "18,5"
    },
    {
      "Serie": "100 150 07 12",
      "Descriere": "150-07-120",
      "Lungime": 1500,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 68,
      "Conținut Ulei": "19,9"
    },
    {
      "Serie": "100 175 07 12",
      "Descriere": "175-07-120",
      "Lungime": 1750,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 76,
      "Conținut Ulei": "22,7"
    },
    {
      "Serie": "100 210 07 12",
      "Descriere": "210-07-120",
      "Lungime": 2100,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 82,
      "Conținut Ulei": "26,7"
    },
    {
      "Serie": "100 260 07 12",
      "Descriere": "260-07-120",
      "Lungime": 2600,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 95,
      "Conținut Ulei": "32,4"
    },
    {
      "Serie": "100 300 07 12",
      "Descriere": "300-07-120",
      "Lungime": 3000,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 106,
      "Conținut Ulei": "37,2"
    },
    {
      "Serie": "100 350 07 12",
      "Descriere": "350-07-120",
      "Lungime": 3500,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 118,
      "Conținut Ulei": "42,9"
    },
    {
      "Serie": "100 100 04 18",
      "Descriere": "100-04-180",
      "Lungime": 1000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 51,
      "Conținut Ulei": "14,2"
    },
    {
      "Serie": "100 120 04 18",
      "Descriere": "120-04-180",
      "Lungime": 1200,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 55,
      "Conținut Ulei": "16,9"
    },
    {
      "Serie": "100 150 04 18",
      "Descriere": "150-04-180",
      "Lungime": 1480,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 61,
      "Conținut Ulei": "20,6"
    },
    {
      "Serie": "100 172 04 18",
      "Descriere": "172-04-180",
      "Lungime": 1720,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 66,
      "Conținut Ulei": "23,9"
    },
    {
      "Serie": "100 200 04 18",
      "Descriere": "200-04-180",
      "Lungime": 2000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 72,
      "Conținut Ulei": "26,7"
    },
    {
      "Serie": "100 240 04 18",
      "Descriere": "240-04-180",
      "Lungime": 2400,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 80,
      "Conținut Ulei": "33"
    },
    {
      "Serie": "100 150 06 18",
      "Descriere": "150-06-180",
      "Lungime": 1500,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 76,
      "Conținut Ulei": "21,6"
    },
    {
      "Serie": "100 180 06 18",
      "Descriere": "180-06-180",
      "Lungime": 1720,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 84,
      "Conținut Ulei": "25,5"
    },
    {
      "Serie": "100 222 06 18",
      "Descriere": "222-06-180",
      "Lungime": 2220,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 96,
      "Conținut Ulei": "30,9"
    },
    {
      "Serie": "100 260 06 18",
      "Descriere": "260-06-180",
      "Lungime": 2600,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 108,
      "Conținut Ulei": "35,5"
    },
    {
      "Serie": "100 300 06 18",
      "Descriere": "300-06-180",
      "Lungime": 3000,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 118,
      "Conținut Ulei": "40,8"
    },
    {
      "Serie": "100 330 06 18",
      "Descriere": "330-06-180",
      "Lungime": 3300,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 128,
      "Conținut Ulei": "44,7"
    },
    {
      "Serie": "100 360 06 18",
      "Descriere": "360-06-180",
      "Lungime": 3600,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 138,
      "Conținut Ulei": "48,5"
    },
    {
      "Serie": "100 125 05 20",
      "Descriere": "125-05-200",
      "Lungime": 1250,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 71,
      "Conținut Ulei": "20,3"
    },
    {
      "Serie": "100 150 05 20",
      "Descriere": "150-05-200",
      "Lungime": 1500,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 79,
      "Conținut Ulei": "23,9"
    },
    {
      "Serie": "100 185 05 20",
      "Descriere": "185-05-200",
      "Lungime": 1850,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 90,
      "Conținut Ulei": "29"
    },
    {
      "Serie": "100 215 05 20",
      "Descriere": "215-05-200",
      "Lungime": 2150,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 98,
      "Conținut Ulei": "33,3"
    },
    {
      "Serie": "100 250 05 20",
      "Descriere": "250-05-200",
      "Lungime": 2500,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 106,
      "Conținut Ulei": "38,4"
    },
    {
      "Serie": "100 275 05 20",
      "Descriere": "275-05-200",
      "Lungime": 2750,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 115,
      "Conținut Ulei": "42"
    },
    {
      "Serie": "100 300 05 20",
      "Descriere": "300-05-200",
      "Lungime": 3000,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 124,
      "Conținut Ulei": "45,6"
    },
    {
      "Serie": "100 100 04 22",
      "Descriere": "100-04-220",
      "Lungime": 1000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 72,
      "Conținut Ulei": "18,3"
    },
    {
      "Serie": "100 120 04 22",
      "Descriere": "120-04-220",
      "Lungime": 1200,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 79,
      "Conținut Ulei": "21,6"
    },
    {
      "Serie": "100 150 04 22",
      "Descriere": "150-04-220",
      "Lungime": 1480,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 88,
      "Conținut Ulei": "26,1"
    },
    {
      "Serie": "100 172 04 22",
      "Descriere": "172-04-220",
      "Lungime": 1720,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 98,
      "Conținut Ulei": "30"
    },
    {
      "Serie": "100 200 04 22",
      "Descriere": "200-04-220",
      "Lungime": 2000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 105,
      "Conținut Ulei": "34,5"
    },
    {
      "Serie": "100 220 04 22",
      "Descriere": "220-04-220",
      "Lungime": 2200,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 115,
      "Conținut Ulei": "37,8"
    },
    {
      "Serie": "100 240 04 22",
      "Descriere": "240-04-220",
      "Lungime": 2400,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 122,
      "Conținut Ulei": "41"
    }
  ]
  },
  {
    id: 3,
    value: [{
      "Serie": "135 050 02 30",
      "Descriere": "50-02-30Q",
      "Lungime": 500,
      "Nr. Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 20,
      "Conținut Ulei": "2,4"
    },
    {
      "Serie": "135 060 02 30",
      "Descriere": "60-02-30Q",
      "Lungime": 600,
      "Nr. Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "2,8"
    },
    {
      "Serie": "135 070 02 30",
      "Descriere": "70-02-30Q",
      "Lungime": 700,
      "Nr. Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 24,
      "Conținut Ulei": "3,2"
    },
    {
      "Serie": "135 036 03 30",
      "Descriere": "36-03-30Q",
      "Lungime": 360,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 13,
      "Conținut Ulei": "1,6"
    },
    {
      "Serie": "135 050 03 30",
      "Descriere": "50-03-30Q",
      "Lungime": 500,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 14,
      "Conținut Ulei": "2,0"
    },
    {
      "Serie": "135 060 03 30",
      "Descriere": "60-03-30Q",
      "Lungime": 600,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 16,
      "Conținut Ulei": "2,4"
    },
    {
      "Serie": "135 070 03 30",
      "Descriere": "70-03-30Q",
      "Lungime": 700,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 17,
      "Conținut Ulei": "2,7"
    },
    {
      "Serie": "135 080 03 30",
      "Descriere": "80-03-30Q",
      "Lungime": 800,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 19,
      "Conținut Ulei": "3,1"
    },
    {
      "Serie": "135 090 03 30",
      "Descriere": "90-03-30Q",
      "Lungime": 900,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 20,
      "Conținut Ulei": "3,4"
    },
    {
      "Serie": "135 105 03 30",
      "Descriere": "105-03-30Q",
      "Lungime": 1050,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 22,
      "Conținut Ulei": "4,0"
    },
    {
      "Serie": "135 050 03 50",
      "Descriere": "50-03-50Q",
      "Lungime": 500,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "3,0"
    },
    {
      "Serie": "135 060 03 50",
      "Descriere": "60-03-50Q",
      "Lungime": 600,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 23,
      "Conținut Ulei": "3,6"
    },
    {
      "Serie": "135 070 03 50",
      "Descriere": "70-03-50Q",
      "Lungime": 700,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 25,
      "Conținut Ulei": "4,0"
    },
    {
      "Serie": "135 080 03 50",
      "Descriere": "80-03-50Q",
      "Lungime": 800,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 27,
      "Conținut Ulei": "4,6"
    },
    {
      "Serie": "135 090 03 50",
      "Descriere": "90-03-50Q",
      "Lungime": 900,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 29,
      "Conținut Ulei": "5,1"
    },
    {
      "Serie": "135 105 03 50",
      "Descriere": "105-03-50Q",
      "Lungime": 1050,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 32,
      "Conținut Ulei": "5,9"
    },
    {
      "Serie": "135 115 03 50",
      "Descriere": "115-03-50Q",
      "Lungime": 1150,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 35,
      "Conținut Ulei": "6,4"
    },
    {
      "Serie": "135 048 04 50",
      "Descriere": "48-04-50Q",
      "Lungime": 480,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 17,
      "Conținut Ulei": "2,6"
    },
    {
      "Serie": "135 060 04 50",
      "Descriere": "60-04-50Q",
      "Lungime": 600,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 18,
      "Conținut Ulei": "3,1"
    },
    {
      "Serie": "135 068 04 50",
      "Descriere": "68-04-50Q",
      "Lungime": 680,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 19,
      "Conținut Ulei": "3,5"
    },
    {
      "Serie": "135 085 04 50",
      "Descriere": "85-04-50Q",
      "Lungime": 850,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 22,
      "Conținut Ulei": "4,2"
    },
    {
      "Serie": "135 105 04 50",
      "Descriere": "105-04-50Q",
      "Lungime": 1050,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 25,
      "Conținut Ulei": "5,1"
    },
    {
      "Serie": "135 115 04 50",
      "Descriere": "115-04-50Q",
      "Lungime": 1150,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 27,
      "Conținut Ulei": "5,5"
    },
    {
      "Serie": "135 060 04 80",
      "Descriere": "60-04-80Q",
      "Lungime": 600,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 24,
      "Conținut Ulei": "4,5"
    },
    {
      "Serie": "135 068 04 80",
      "Descriere": "68-04-80Q",
      "Lungime": 680,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 25,
      "Conținut Ulei": "5,0"
    },
    {
      "Serie": "135 085 04 80",
      "Descriere": "85-04-80Q",
      "Lungime": 850,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 28,
      "Conținut Ulei": "6,1"
    },
    {
      "Serie": "135 095 04 80",
      "Descriere": "95-04-80Q",
      "Lungime": 950,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 30,
      "Conținut Ulei": "6,8"
    },
    {
      "Serie": "135 105 04 80",
      "Descriere": "105-04-80Q",
      "Lungime": 1050,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 32,
      "Conținut Ulei": "7,4"
    },
    {
      "Serie": "135 120 04 80",
      "Descriere": "120-04-80Q",
      "Lungime": 1200,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 35,
      "Conținut Ulei": "8,4"
    },
    {
      "Serie": "135 130 04 80",
      "Descriere": "130-04-80Q",
      "Lungime": 1300,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 36,
      "Conținut Ulei": "9,0"
    },
    {
      "Serie": "135 150 04 80",
      "Descriere": "150-04-80Q",
      "Lungime": 1500,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 40,
      "Conținut Ulei": "10,3"
    },
    {
      "Serie": "135 170 04 80",
      "Descriere": "170-04-80Q",
      "Lungime": 1700,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 44,
      "Conținut Ulei": "11,6"
    },
    {
      "Serie": "135 200 04 80",
      "Descriere": "200-04-80Q",
      "Lungime": 2000,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 53,
      "Conținut Ulei": "13,5"
    },
    {
      "Serie": "135 050 05 50",
      "Descriere": "50-05-50Q",
      "Lungime": 500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 19,
      "Conținut Ulei": "3,5"
    },
    {
      "Serie": "135 070 05 50",
      "Descriere": "70-05-50Q",
      "Lungime": 700,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "4,6"
    },
    {
      "Serie": "135 085 05 50",
      "Descriere": "85-05-50Q",
      "Lungime": 850,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 23,
      "Conținut Ulei": "5,4"
    },
    {
      "Serie": "135 105 05 50",
      "Descriere": "105-05-50Q",
      "Lungime": 1050,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 26,
      "Conținut Ulei": "6,5"
    },
    {
      "Serie": "135 115 05 50",
      "Descriere": "115-05-50Q",
      "Lungime": 1150,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 28,
      "Conținut Ulei": "7,0"
    },
    {
      "Serie": "135 125 05 50",
      "Descriere": "125-05-50Q",
      "Lungime": 1250,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 29,
      "Conținut Ulei": "7,6"
    },
    {
      "Serie": "135 150 05 50",
      "Descriere": "150-05-50Q",
      "Lungime": 1500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 33,
      "Conținut Ulei": "9,0"
    },
    {
      "Serie": "135 185 05 50",
      "Descriere": "185-05-50Q",
      "Lungime": 1850,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 38,
      "Conținut Ulei": "11,0"
    },
    {
      "Serie": "135 060 06 50",
      "Descriere": "60-06-50Q",
      "Lungime": 600,
      "Nr. Unitati": 6,
      "D32": "O",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 18,
      "Conținut Ulei": "3,6"
    },
    {
      "Serie": "135 085 06 50",
      "Descriere": "85-06-50Q",
      "Lungime": 850,
      "Nr. Unitati": 6,
      "D32": "O",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "4,9"
    },
    {
      "Serie": "135 105 06 50",
      "Descriere": "105-06-50Q",
      "Lungime": 1020,
      "Nr. Unitati": 6,
      "D32": "O",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 23,
      "Conținut Ulei": "5,6"
    },
    {
      "Serie": "135 115 06 50",
      "Descriere": "115-06-50Q",
      "Lungime": 1150,
      "Nr. Unitati": 6,
      "D32": "O",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 25,
      "Conținut Ulei": "6,3"
    },
    {
      "Serie": "135 125 06 50",
      "Descriere": "125-06-50Q",
      "Lungime": 1250,
      "Nr. Unitati": 6,
      "D32": "O",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 26,
      "Conținut Ulei": "6,8"
    },
    {
      "Serie": "135 100 05 90",
      "Descriere": "100-05-90Q",
      "Lungime": 1000,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 39,
      "Conținut Ulei": "9,2"
    },
    {
      "Serie": "135 125 05 90",
      "Descriere": "125-05-90Q",
      "Lungime": 1250,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 45,
      "Conținut Ulei": "11,2"
    },
    {
      "Serie": "135 150 05 90",
      "Descriere": "150-05-90Q",
      "Lungime": 1500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 50,
      "Conținut Ulei": "13,1"
    },
    {
      "Serie": "135 170 05 90",
      "Descriere": "170-05-90Q",
      "Lungime": 1700,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 54,
      "Conținut Ulei": "14,7"
    },
    {
      "Serie": "135 185 05 90",
      "Descriere": "185-05-90Q",
      "Lungime": 1850,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 58,
      "Conținut Ulei": "15,9"
    },
    {
      "Serie": "135 215 05 90",
      "Descriere": "215-05-90Q",
      "Lungime": 2150,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 61,
      "Conținut Ulei": "18,3"
    },
    {
      "Serie": "135 250 05 90",
      "Descriere": "250-05-90Q",
      "Lungime": 2500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 70,
      "Conținut Ulei": "21,0"
    },
    {
      "Serie": "135 150 06 10",
      "Descriere": "150-06-100Q",
      "Lungime": 1500,
      "Nr. Unitati": 6,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 10000,
      "Greutate(KG)": 56,
      "Conținut Ulei": "16,4"
    },
    {
      "Serie": "135 170 06 10",
      "Descriere": "170-06-100Q",
      "Lungime": 1700,
      "Nr. Unitati": 6,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 10000,
      "Greutate(KG)": 62,
      "Conținut Ulei": "18,5"
    },
    {
      "Serie": "135 190 06 10",
      "Descriere": "190-06-100Q",
      "Lungime": 1900,
      "Nr. Unitati": 6,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 10000,
      "Greutate(KG)": 65,
      "Conținut Ulei": "20,2"
    },
    {
      "Serie": "135 230 06 10",
      "Descriere": "230-06-100Q",
      "Lungime": 2300,
      "Nr. Unitati": 6,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 10000,
      "Greutate(KG)": 74,
      "Conținut Ulei": "24,1"
    },
    {
      "Serie": "135 260 06 10",
      "Descriere": "260-06-100Q",
      "Lungime": 2600,
      "Nr. Unitati": 6,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 10000,
      "Greutate(KG)": 80,
      "Conținut Ulei": "26,9"
    },
    {
      "Serie": "135 100 05 12",
      "Descriere": "100-05-120Q",
      "Lungime": 1000,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 44,
      "Conținut Ulei": "12,6"
    },
    {
      "Serie": "135 125 05 12",
      "Descriere": "125-05-120Q",
      "Lungime": 1250,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 54,
      "Conținut Ulei": "15,4"
    },
    {
      "Serie": "135 150 05 12",
      "Descriere": "150-05-120Q",
      "Lungime": 1500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 56,
      "Conținut Ulei": "18,1"
    },
    {
      "Serie": "135 185 05 12",
      "Descriere": "185-05-120Q",
      "Lungime": 1850,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 63,
      "Conținut Ulei": "21,8"
    },
    {
      "Serie": "135 215 05 12",
      "Descriere": "215-05-120Q",
      "Lungime": 2150,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 70,
      "Conținut Ulei": "25,1"
    },
    {
      "Serie": "135 260 05 12",
      "Descriere": "260-05-120Q",
      "Lungime": 2600,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 100,
      "Conținut Ulei": "30,0"
    }
  ]
  },
  {
    id: 4,
    value: [{
      "Serie": "100 050 20 30",
      "Descriere": "50-20-30",
      "Lungime": 500,
      "Nr Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 18,
      "Conținut Ulei": "2,4"
    },
    {
      "Serie": "100 060 20 30",
      "Descriere": "60-20-30",
      "Lungime": 600,
      "Nr Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 20,
      "Conținut Ulei": "2,8"
    },
    {
      "Serie": "100 070 20 30",
      "Descriere": "70-20-30",
      "Lungime": 700,
      "Nr Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 22,
      "Conținut Ulei": "3,2"
    },
    {
      "Serie": "100 050 30 30",
      "Descriere": "50-30-30",
      "Lungime": 500,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 12,
      "Conținut Ulei": "2,0"
    },
    {
      "Serie": "100 060 30 30",
      "Descriere": "60-30-30",
      "Lungime": 600,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 14,
      "Conținut Ulei": "2,4"
    },
    {
      "Serie": "100 070 30 30",
      "Descriere": "70-30-30",
      "Lungime": 700,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 16,
      "Conținut Ulei": "2,7"
    },
    {
      "Serie": "100 080 30 30",
      "Descriere": "80-30-30",
      "Lungime": 800,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 17,
      "Conținut Ulei": "3,1"
    },
    {
      "Serie": "100 090 30 30",
      "Descriere": "90-30-30",
      "Lungime": 900,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 19,
      "Conținut Ulei": "3,4"
    },
    {
      "Serie": "100 050 30 50",
      "Descriere": "50-30-50",
      "Lungime": 500,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 19,
      "Conținut Ulei": "3,0"
    },
    {
      "Serie": "100 060 30 50",
      "Descriere": "60-30-50",
      "Lungime": 600,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "3,6"
    },
    {
      "Serie": "100 070 30 50",
      "Descriere": "70-30-50",
      "Lungime": 700,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 23,
      "Conținut Ulei": "4,0"
    },
    {
      "Serie": "100 080 30 50",
      "Descriere": "80-30-50",
      "Lungime": 800,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 25,
      "Conținut Ulei": "4,6"
    },
    {
      "Serie": "100 090 30 50",
      "Descriere": "90-30-50",
      "Lungime": 900,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 27,
      "Conținut Ulei": "5,1"
    },
    {
      "Serie": "100 105 30 50",
      "Descriere": "105-30-50",
      "Lungime": 1050,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 30,
      "Conținut Ulei": "5,9"
    },
    {
      "Serie": "100 115 30 50",
      "Descriere": "115-30-50",
      "Lungime": 1150,
      "Nr Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 33,
      "Conținut Ulei": "6,4"
    },
    {
      "Serie": "100 048 40 50",
      "Descriere": "48-40-50",
      "Lungime": 480,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 15,
      "Conținut Ulei": "2,6"
    },
    {
      "Serie": "100 060 40 50",
      "Descriere": "60-40-50",
      "Lungime": 600,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 17,
      "Conținut Ulei": "3,1"
    },
    {
      "Serie": "100 068 40 50",
      "Descriere": "68-40-50",
      "Lungime": 680,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 18,
      "Conținut Ulei": "3,5"
    },
    {
      "Serie": "100 085 40 50",
      "Descriere": "85-40-50",
      "Lungime": 850,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 20,
      "Conținut Ulei": "4,2"
    },
    {
      "Serie": "100 105 40 50",
      "Descriere": "105-40-50",
      "Lungime": 1050,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 24,
      "Conținut Ulei": "5,1"
    },
    {
      "Serie": "100 115 40 50",
      "Descriere": "115-40-50",
      "Lungime": 1150,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 25,
      "Conținut Ulei": "5,5"
    },
    {
      "Serie": "100 060 40 80",
      "Descriere": "60-40-80",
      "Lungime": 600,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 22,
      "Conținut Ulei": "4,5"
    },
    {
      "Serie": "100 068 40 80",
      "Descriere": "68-40-80",
      "Lungime": 680,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 23,
      "Conținut Ulei": "5,0"
    },
    {
      "Serie": "100 085 40 80",
      "Descriere": "85-40-80",
      "Lungime": 850,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 26,
      "Conținut Ulei": "6,1"
    },
    {
      "Serie": "100 095 40 80",
      "Descriere": "95-40-80",
      "Lungime": 950,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 28,
      "Conținut Ulei": "6,8"
    },
    {
      "Serie": "100 105 40 80",
      "Descriere": "105-40-80",
      "Lungime": 1050,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 30,
      "Conținut Ulei": "7,4"
    },
    {
      "Serie": "100 120 40 80",
      "Descriere": "120-40-80",
      "Lungime": 1200,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 33,
      "Conținut Ulei": "8,4"
    },
    {
      "Serie": "100 130 40 80",
      "Descriere": "130-40-80",
      "Lungime": 1300,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 35,
      "Conținut Ulei": "9,0"
    },
    {
      "Serie": "100 150 40 80",
      "Descriere": "150-40-80",
      "Lungime": 1500,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 38,
      "Conținut Ulei": "10,3"
    },
    {
      "Serie": "100 170 40 80",
      "Descriere": "170-40-80",
      "Lungime": 1700,
      "Nr Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 42,
      "Conținut Ulei": "11,6"
    },
    {
      "Serie": "100 050 50 50",
      "Descriere": "50-50-50",
      "Lungime": 500,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 17,
      "Conținut Ulei": "3,5"
    },
    {
      "Serie": "100 070 50 50",
      "Descriere": "70-50-50",
      "Lungime": 700,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 20,
      "Conținut Ulei": "4,6"
    },
    {
      "Serie": "100 085 50 50",
      "Descriere": "85-50-50",
      "Lungime": 850,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 22,
      "Conținut Ulei": "5,4"
    },
    {
      "Serie": "100 115 50 50",
      "Descriere": "115-50-50",
      "Lungime": 1150,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 26,
      "Conținut Ulei": "7,0"
    },
    {
      "Serie": "100 125 50 50",
      "Descriere": "125-50-50",
      "Lungime": 1250,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 28,
      "Conținut Ulei": "7,6"
    },
    {
      "Serie": "100 150 50 50",
      "Descriere": "150-50-50",
      "Lungime": 1500,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 32,
      "Conținut Ulei": "9,0"
    },
    {
      "Serie": "100 185 50 50",
      "Descriere": "185-50-50",
      "Lungime": 1850,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 38,
      "Conținut Ulei": "11,0"
    },
    {
      "Serie": "100 100 50 90",
      "Descriere": "100-50-90",
      "Lungime": 1000,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 36,
      "Conținut Ulei": "9,2"
    },
    {
      "Serie": "100 125 50 90",
      "Descriere": "125-50-90",
      "Lungime": 1250,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 42,
      "Conținut Ulei": "11,2"
    },
    {
      "Serie": "100 150 50 90",
      "Descriere": "150-50-90",
      "Lungime": 1500,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 47,
      "Conținut Ulei": "13,1"
    },
    {
      "Serie": "100 185 50 90",
      "Descriere": "185-50-90",
      "Lungime": 1850,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 55,
      "Conținut Ulei": "15,9"
    },
    {
      "Serie": "100 215 50 90",
      "Descriere": "215-50-90",
      "Lungime": 2150,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 58,
      "Conținut Ulei": "18,3"
    },
    {
      "Serie": "100 250 50 90",
      "Descriere": "250-50-90",
      "Lungime": 2500,
      "Nr Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 68,
      "Conținut Ulei": "21,0"
    }
  ]
  },
  {
    id: 5,
    value: [{
      "Serie": "100 100 40 12",
      "Descriere": "100-40-120",
      "Lungime": 1000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "D166": "",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 50,
      "Conținut Ulei": "9,6"
    },
    {
      "Serie": "100 120 40 12",
      "Descriere": "120-40-120",
      "Lungime": 1200,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "D166": "",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 53,
      "Conținut Ulei": "11,4"
    },
    {
      "Serie": "100 150 40 12",
      "Descriere": "150-40-120",
      "Lungime": 1480,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "D166": "",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 57,
      "Conținut Ulei": "13,9"
    },
    {
      "Serie": "100 172 40 12",
      "Descriere": "172-40-120",
      "Lungime": 1720,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "D166": "",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 61,
      "Conținut Ulei": "16,8"
    },
    {
      "Serie": "100 200 40 12",
      "Descriere": "200-40-120",
      "Lungime": 2000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "D166": "",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 65,
      "Conținut Ulei": "18,5"
    },
    {
      "Serie": "100 150 70 12",
      "Descriere": "150-70-120",
      "Lungime": 1500,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 65,
      "Conținut Ulei": "19,9"
    },
    {
      "Serie": "100 175 70 12",
      "Descriere": "175-70-120",
      "Lungime": 1750,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 73,
      "Conținut Ulei": "22,7"
    },
    {
      "Serie": "100 210 70 12",
      "Descriere": "210-70-120",
      "Lungime": 2100,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 79,
      "Conținut Ulei": "26,7"
    },
    {
      "Serie": "100 260 70 12",
      "Descriere": "260-70-120",
      "Lungime": 2600,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 92,
      "Conținut Ulei": "32,4"
    },
    {
      "Serie": "100 300 70 12",
      "Descriere": "300-70-120",
      "Lungime": 3000,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 103,
      "Conținut Ulei": "37,2"
    },
    {
      "Serie": "100 350 70 12",
      "Descriere": "350-70-120",
      "Lungime": 3500,
      "Nr. Unitati": 7,
      "D61": "O",
      "D72": "",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 12000,
      "Greutate(KG)": 115,
      "Conținut Ulei": "42,9"
    },
    {
      "Serie": "100 100 40 18",
      "Descriere": "100-40-180",
      "Lungime": 1000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 50,
      "Conținut Ulei": "14,2"
    },
    {
      "Serie": "100 120 40 18",
      "Descriere": "120-40-180",
      "Lungime": 1200,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 54,
      "Conținut Ulei": "16,9"
    },
    {
      "Serie": "100 150 40 18",
      "Descriere": "150-40-180",
      "Lungime": 1480,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 60,
      "Conținut Ulei": "20,6"
    },
    {
      "Serie": "100 172 40 18",
      "Descriere": "172-40-180",
      "Lungime": 1720,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 65,
      "Conținut Ulei": "23,9"
    },
    {
      "Serie": "100 200 40 18",
      "Descriere": "200-40-180",
      "Lungime": 2000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 71,
      "Conținut Ulei": "26,7"
    },
    {
      "Serie": "100 240 40 18",
      "Descriere": "240-40-180",
      "Lungime": 2400,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 79,
      "Conținut Ulei": "33,0"
    },
    {
      "Serie": "100 150 60 18",
      "Descriere": "150-60-180",
      "Lungime": 1500,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 72,
      "Conținut Ulei": "21,6"
    },
    {
      "Serie": "100 180 60 18",
      "Descriere": "180-60-180",
      "Lungime": 1800,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 80,
      "Conținut Ulei": "25,5"
    },
    {
      "Serie": "100 222 60 18",
      "Descriere": "222-60-180",
      "Lungime": 2220,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 92,
      "Conținut Ulei": "30,9"
    },
    {
      "Serie": "100 260 60 18",
      "Descriere": "260-60-180",
      "Lungime": 2600,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 104,
      "Conținut Ulei": "35,5"
    },
    {
      "Serie": "100 300 60 18",
      "Descriere": "300-60-180",
      "Lungime": 3000,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 114,
      "Conținut Ulei": "40,8"
    },
    {
      "Serie": "100 330 60 18",
      "Descriere": "330-60-180",
      "Lungime": 3300,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 124,
      "Conținut Ulei": "44,7"
    },
    {
      "Serie": "100 360 60 18",
      "Descriere": "360-60-180",
      "Lungime": 3600,
      "Nr. Unitati": 6,
      "D61": "",
      "D72": "O",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 18000,
      "Greutate(KG)": 134,
      "Conținut Ulei": "48,5"
    },
    {
      "Serie": "100 125 50 20",
      "Descriere": "125-50-200",
      "Lungime": 1250,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 70,
      "Conținut Ulei": "20,3"
    },
    {
      "Serie": "100 150 50 20",
      "Descriere": "150-50-200",
      "Lungime": 1500,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 78,
      "Conținut Ulei": "23,9"
    },
    {
      "Serie": "100 185 50 20",
      "Descriere": "185-50-200",
      "Lungime": 1850,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 89,
      "Conținut Ulei": "29,0"
    },
    {
      "Serie": "100 215 50 20",
      "Descriere": "215-50-200",
      "Lungime": 2150,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 97,
      "Conținut Ulei": "33,3"
    },
    {
      "Serie": "100 250 50 20",
      "Descriere": "250-50-200",
      "Lungime": 2500,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 105,
      "Conținut Ulei": "38,4"
    },
    {
      "Serie": "100 275 50 20",
      "Descriere": "275-50-200",
      "Lungime": 2750,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 114,
      "Conținut Ulei": "42,0"
    },
    {
      "Serie": "100 300 50 20",
      "Descriere": "300-50-200",
      "Lungime": 3000,
      "Nr. Unitati": 5,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 20000,
      "Greutate(KG)": 123,
      "Conținut Ulei": "45,6"
    },
    {
      "Serie": "100 100 40 22",
      "Descriere": "100-40-220",
      "Lungime": 1000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 70,
      "Conținut Ulei": "18,3"
    },
    {
      "Serie": "100 120 40 22",
      "Descriere": "120-40-220",
      "Lungime": 1200,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 76,
      "Conținut Ulei": "21,6"
    },
    {
      "Serie": "100 150 40 22",
      "Descriere": "150-40-220",
      "Lungime": 1480,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 86,
      "Conținut Ulei": "26,1"
    },
    {
      "Serie": "100 172 40 22",
      "Descriere": "172-40-220",
      "Lungime": 1720,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 96,
      "Conținut Ulei": "30,0"
    },
    {
      "Serie": "100 200 40 22",
      "Descriere": "200-40-220",
      "Lungime": 2000,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 103,
      "Conținut Ulei": "34,5"
    },
    {
      "Serie": "100 220 40 22",
      "Descriere": "220-40-220",
      "Lungime": 2200,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 113,
      "Conținut Ulei": "37,8"
    },
    {
      "Serie": "100 240 40 22",
      "Descriere": "240-40-220",
      "Lungime": 2400,
      "Nr. Unitati": 4,
      "D61": "",
      "D72": "",
      "D76": "",
      "D91": "",
      "D107": "O",
      "D127": "O",
      "D146": "O",
      "D166": "O",
      "Capacitate(KG)": 22000,
      "Greutate(KG)": 120,
      "Conținut Ulei": "41,0"
    }
  ]
  },
  {
    id: 6,
    value: [{
      "Serie": "101 050 21 30",
      "Descriere": "50-21-30",
      "Lungime": 500,
      "Nr. Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 19,
      "Conținut Ulei": "2,4"
    },
    {
      "Serie": "101 060 21 30",
      "Descriere": "60-21-30",
      "Lungime": 600,
      "Nr. Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "2,8"
    },
    {
      "Serie": "101 070 21 30",
      "Descriere": "70-21-30",
      "Lungime": 700,
      "Nr. Unitati": 2,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 22,
      "Conținut Ulei": "3,2"
    },
    {
      "Serie": "101 050 31 30",
      "Descriere": "50-31-30",
      "Lungime": 500,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 13,
      "Conținut Ulei": "2,0"
    },
    {
      "Serie": "101 060 31 30",
      "Descriere": "60-31-30",
      "Lungime": 600,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 14,
      "Conținut Ulei": "2,4"
    },
    {
      "Serie": "101 070 31 30",
      "Descriere": "70-31-30",
      "Lungime": 700,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 17,
      "Conținut Ulei": "2,7"
    },
    {
      "Serie": "101 080 31 30",
      "Descriere": "80-31-30",
      "Lungime": 800,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 18,
      "Conținut Ulei": "3,1"
    },
    {
      "Serie": "101 090 31 30",
      "Descriere": "90-31-30",
      "Lungime": 900,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 20,
      "Conținut Ulei": "3,4"
    },
    {
      "Serie": "101 105 31 30",
      "Descriere": "105-31-30",
      "Lungime": 1050,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 3000,
      "Greutate(KG)": 22,
      "Conținut Ulei": "4,0"
    },
    {
      "Serie": "101 050 31 50",
      "Descriere": "50-31-50",
      "Lungime": 500,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 20,
      "Conținut Ulei": "3,0"
    },
    {
      "Serie": "101 060 31 50",
      "Descriere": "60-31-50",
      "Lungime": 600,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 22,
      "Conținut Ulei": "3,6"
    },
    {
      "Serie": "101 070 31 50",
      "Descriere": "70-31-50",
      "Lungime": 700,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 24,
      "Conținut Ulei": "4,0"
    },
    {
      "Serie": "101 080 31 50",
      "Descriere": "80-31-50",
      "Lungime": 800,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 26,
      "Conținut Ulei": "4,6"
    },
    {
      "Serie": "101 090 31 50",
      "Descriere": "90-31-50",
      "Lungime": 900,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 28,
      "Conținut Ulei": "5,1"
    },
    {
      "Serie": "101 105 31 50",
      "Descriere": "105-31-50",
      "Lungime": 1050,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 31,
      "Conținut Ulei": "5,9"
    },
    {
      "Serie": "101 115 31 50",
      "Descriere": "115-31-50",
      "Lungime": 1150,
      "Nr. Unitati": 3,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 34,
      "Conținut Ulei": "6,4"
    },
    {
      "Serie": "101 048 41 50",
      "Descriere": "48-41-50",
      "Lungime": 480,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 16,
      "Conținut Ulei": "2,6"
    },
    {
      "Serie": "101 060 41 50",
      "Descriere": "60-41-50",
      "Lungime": 600,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 18,
      "Conținut Ulei": "3,1"
    },
    {
      "Serie": "101 068 41 50",
      "Descriere": "68-41-50",
      "Lungime": 680,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 19,
      "Conținut Ulei": "3,5"
    },
    {
      "Serie": "101 085 41 50",
      "Descriere": "85-41-50",
      "Lungime": 850,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "4,2"
    },
    {
      "Serie": "101 105 41 50",
      "Descriere": "105-41-50",
      "Lungime": 1050,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 25,
      "Conținut Ulei": "5,1"
    },
    {
      "Serie": "101 115 41 50",
      "Descriere": "115-41-50",
      "Lungime": 1150,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 26,
      "Conținut Ulei": "5,5"
    },
    {
      "Serie": "101 060 41 80",
      "Descriere": "60-41-80",
      "Lungime": 600,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 23,
      "Conținut Ulei": "4,5"
    },
    {
      "Serie": "101 068 41 80",
      "Descriere": "68-41-80",
      "Lungime": 680,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 24,
      "Conținut Ulei": "5,0"
    },
    {
      "Serie": "101 085 41 80",
      "Descriere": "85-41-80",
      "Lungime": 850,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 27,
      "Conținut Ulei": "6,1"
    },
    {
      "Serie": "101 095 41 80",
      "Descriere": "95-41-80",
      "Lungime": 950,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 29,
      "Conținut Ulei": "6,8"
    },
    {
      "Serie": "101 105 41 80",
      "Descriere": "105-41-80",
      "Lungime": 1050,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 31,
      "Conținut Ulei": "7,4"
    },
    {
      "Serie": "101 120 41 80",
      "Descriere": "120-41-80",
      "Lungime": 1200,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 34,
      "Conținut Ulei": "8,4"
    },
    {
      "Serie": "101 130 41 80",
      "Descriere": "130-41-80",
      "Lungime": 1300,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 36,
      "Conținut Ulei": "9,0"
    },
    {
      "Serie": "101 150 41 80",
      "Descriere": "150-41-80",
      "Lungime": 1500,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 39,
      "Conținut Ulei": "10,3"
    },
    {
      "Serie": "101 170 41 80",
      "Descriere": "170-41-80",
      "Lungime": 1700,
      "Nr. Unitati": 4,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 8000,
      "Greutate(KG)": 43,
      "Conținut Ulei": "11,6"
    },
    {
      "Serie": "101 050 51 50",
      "Descriere": "50-51-50",
      "Lungime": 500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 18,
      "Conținut Ulei": "3,5"
    },
    {
      "Serie": "101 070 51 50",
      "Descriere": "70-51-50",
      "Lungime": 700,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 21,
      "Conținut Ulei": "4,6"
    },
    {
      "Serie": "101 085 51 50",
      "Descriere": "85-51-50",
      "Lungime": 850,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 23,
      "Conținut Ulei": "5,4"
    },
    {
      "Serie": "101 115 51 50",
      "Descriere": "115-51-50",
      "Lungime": 1150,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 27,
      "Conținut Ulei": "7,0"
    },
    {
      "Serie": "101 125 51 50",
      "Descriere": "125-51-50",
      "Lungime": 1250,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 29,
      "Conținut Ulei": "7,6"
    },
    {
      "Serie": "101 150 51 50",
      "Descriere": "150-51-50",
      "Lungime": 1500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "O",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "",
      "D146": "",
      "Capacitate(KG)": 5000,
      "Greutate(KG)": 33,
      "Conținut Ulei": "9,0"
    },
    {
      "Serie": "101 100 51 90",
      "Descriere": "100-51-90",
      "Lungime": 1000,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 39,
      "Conținut Ulei": "9,2"
    },
    {
      "Serie": "101 125 51 90",
      "Descriere": "125-51-90",
      "Lungime": 1250,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 44,
      "Conținut Ulei": "11,2"
    },
    {
      "Serie": "101 150 51 90",
      "Descriere": "150-51-90",
      "Lungime": 1500,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 49,
      "Conținut Ulei": "13,1"
    },
    {
      "Serie": "101 185 51 90",
      "Descriere": "185-51-90",
      "Lungime": 1850,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 57,
      "Conținut Ulei": "15,9"
    },
    {
      "Serie": "101 215 51 90",
      "Descriere": "215-51-90",
      "Lungime": 2150,
      "Nr. Unitati": 5,
      "D32": "",
      "D46": "",
      "D61": "O",
      "D76": "O",
      "D91": "O",
      "D107": "O",
      "D127": "O",
      "D146": "",
      "Capacitate(KG)": 9000,
      "Greutate(KG)": 60,
      "Conținut Ulei": "18,3"
    }
  ]
  },
];

export default tableData2;
